<template>
    <div class="about">
        <h1 class="about__title">
            О сервисе
        </h1>
        <p class="about__desc">
            {{ text }}
        </p>
    </div>
</template>

<script>
import './about.scss'

export default {
    name: 'About',
    computed: {
        text() {
            return 'Деньги тут — это удобный инструмент для получения микрозайма. Оставьте заявку на получение денег: работаем со всеми клиентами — не важно, какая у вас кредитная история и имеются ли текущие просрочки.'
        }
    }
}
</script>